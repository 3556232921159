.mouse-pointer-beacon {
    position: absolute;
    cursor: pointer;
    transform: scale(1);
    transition: all 0.2s ease;
    animation: float 2s infinite ease-in-out;
    filter: drop-shadow(0 0 5px rgba(255, 0, 0, 0.5));
  }
  
  .mouse-pointer-beacon.clicking {
    transform: scale(0.85) translateY(4px);
  }
  
  /* Float animation */
  @keyframes float {
    0%, 100% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-6px);
    }
  }
  
  /* Click animation */
  .mouse-pointer-beacon.clicking svg {
    filter: drop-shadow(0 0 8px rgba(255, 0, 0, 0.8)) !important;
  }